import * as React from "react"
import { graphql } from "gatsby";
import { css } from '@emotion/css';

import Container from '../components/layout/container'

import SEO from '../components/seo'
import Section from '../components/editorial/section'
import Quote from "../components/editorial/quote";
import LinkComponent from "../components/editorial/link";

import { typescale } from "../styling/typescale";

// styles
import "./../styling/libraries.scss"
import { colors } from "../styling/colors";
import { breakpoints } from "../styling/breakpoints";

// data
export const query = graphql`
  query MyQuery {
    allSanityLinkList {
      edges {
        node {
          id
          title
          links {
            linkText
            externalURL
          }
        }
      }
    }
    allSanityPartner {
      edges {
        node {
          id
          linkText
          externalURL
          mainImage {
            asset {
              fixed {
                src
              }
            }
          }
        }
      }
    }
    sanitySiteSettings {
      title
      description
      url
      internal {
        content
        description
        ignoreType
        mediaType
      }
      image {
        asset {
          fixed {
            src
            height
            width
          }
        }
      }
      frontpageTitle
      hero {
        _key
        _type
        title
        _rawRichText
        links {
          linkText
          page {
            title
            slug {
              current
            }
          }
        }
        mainImage {
          asset {
            fixed {
              src
            }
          }
        }
      }
      content {
        ... on SanityQuote {
          _key
          _type
          author
          title_1
          title
          quote
          story {
            slug {
              current
            }
          }
          image {
            alt
            asset {
              fixed(width: 530, height: 312) {
                src
              }
            }
          }
        }
        ... on SanitySection {
          _key
          _type
          title
          video
          _rawRichText
          links {
            linkText
            page {
              title
              slug {
                current
              }
            }
          }
          mainImage {
            alt
            asset {
              fixed {
                src
              }
            }
          }
        }
      }
    }
  }
`

const Partners = css`
  text-align: center;
  
  .logo {
    max-width: 200px;
    @media only screen and (${breakpoints.mobile}) {
      margin: 0 auto;
    }
  }
`

// markup
const IndexPage = ({data}) => {

  return (
    <Container>

      <SEO 
        title={data.sanitySiteSettings.title}
        description={data.sanitySiteSettings.description}
        pathname="/"
        image={data.sanitySiteSettings.image.asset && data.sanitySiteSettings.image.asset.fixed ? data.sanitySiteSettings.image.asset.fixed : null}
      />

        {data.sanitySiteSettings.hero &&
          <Section 
            bg={colors.green02} 
            index={0}
            {...data.sanitySiteSettings.hero[0]}
            title={<h1 className="title is-1-desktop">{data.sanitySiteSettings.frontpageTitle}</h1>}
          />
        }

        <div>

          {data.sanitySiteSettings.content.map((item, index) => {

            let element;

            if (item && item.__typename) {

              switch(item.__typename) {
                case 'SanitySection':
                  element = <Section 
                    bg={colors.green02} 
                    key={index + item._type} 
                    index={index} 
                    {...item} 
                    title={<h2 className="title is-3">{item.title}</h2>}
                    />
                  break;
                case 'SanityQuote':
                  element = <Quote key={index + item._type} i={index} {...item} />
                  break;
                default:
                  element = <div>Udefinert innholdstype {item.__typename}</div>
                  break;
              }
            }

            return element;

          })}

          <div className={Partners}>
            <div className="section py-5 my-6">
              <section className="container">

                <div className="columns">
                  <div className="column is-offset-1 is-11">

                    <div className="columns is-multiline  is-align-items-center">
                      {data.allSanityPartner.edges.map((item, i) => 
                        <div key={i} className="column -is-three-quarters-tablet p-4">
                          <LinkComponent 
                            externalURL={item.node.externalURL} 
                            linkText={item.node.linkText} 
                            mainImage={item.node.mainImage}
                          />
                        </div>
                      )}
                    </div>
                  
                  </div>
                </div>


              </section>

            </div>
          </div>

          <div className="section py-5">
            <section className="container">
              <div className="columns is-multiline">
                {data.allSanityLinkList.edges.map((item, i) => 
                  <div className="column is-3 is-offset-1 mb-5" key={i}>
                    <div>
                      
                      <h3 
                        className="title is-6 mb-4"
                        style={typescale['title-3']}>
                        {item.node.title}
                      </h3>

                      <ul>
                      {item.node.links.map(item => 
                        <li key={item.linkText} className="mb-2">
                          <LinkComponent 
                            bold
                            underlined
                            arrow
                            externalURL={item.externalURL} 
                            linkText={item.linkText} 
                            mainImage={item.mainImage}
                          />
                        </li>
                      )}
                      </ul>
                    </div>
                  </div>
                )}
              </div>

            </section>

          </div>

        </div>

    </Container>
  )
}

export default IndexPage
